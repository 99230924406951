import axios from 'axios'
//import {tip} from './utils'

const errorHandle = (status, msg) => {
    console("status="+status);
    console("msg="+msg)
    switch(status) {
        case 404:
            console.log(msg)
            break;
        default:
            console.log("no error");
    }
}

var instance = axios.create({
    baseURL : process.env.VUE_APP_API_BASE_URL
})

instance.interceptors.request.use((config) => {
    return config
}, (error) => {
    return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
    console.log("###"+response.data.header.rtnCode)
    return response
}, (error) => {
    const { response } = error;
    if(response) {
        errorHandle(response.status, response.data.error)
        return Promise.reject(error)
    }else {
        if(!window.navigator.onLine) {
            console.log("network is bad");
        }else {
            return Promise.reject(error)
        }
    }
})

export default function (method, url, data=null) {
    method = method.toLowerCase()
    if(method == "post") {
        return instance.post(url, data)
    }else if(method == "get") {
        return instance.get(url, { params: data })
    }else if(method == "put") {
        return instance.put(url, data)
    }else if(method == "delete") {
        return instance.delete(url)
    }else {
        console.error("unknow method = "+method)
        return false
    }
}
