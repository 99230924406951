<template>
  <v-app>
    <v-app-bar app elevation="0" :hide-on-scroll="true">
      <v-app-bar-nav-icon
        @click.stop="toggleMini"
      ></v-app-bar-nav-icon>
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon>mdi-account</v-icon>
    </v-app-bar>
    <v-navigation-drawer 
      dark
      app
      floating
      permanent
      v-model="drawer"
      :mini-variant="miniVariant"
      :style="navigationDrawer"
    >
      <v-list dense> 
        <v-list-item :class="miniVariant && 'min-logo'">
          <v-list-item-avatar>
            <v-img
              :src="require('./assets/tvgh.png')"
              max-height="32"
              max-width="32"
              contain
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>TSVGH-Kidit</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-group :value="false" color="blue lighten-2">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-folder-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>腹膜透析報表</v-list-item-title>
          </template>
          <v-list-item link to="/monthReport">
            <v-list-item-icon>
              <v-icon>mdi-file-table-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>月報表</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/directorReport">
            <v-list-item-icon>
              <v-icon>mdi-book-open-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>腎臟科主任報表</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/yearReport">
            <v-list-item-icon>
              <v-icon>mdi-file-table-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>年度報表</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/yearStatistics">
            <v-list-item-icon>
              <v-icon>mdi-file-table-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>年度統計</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-list>
        <v-list-group :value="false" color="blue lighten-2">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-folder-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>腹膜透析圖表</v-list-item-title>
          </template>
          <v-list-item link to="/abandonment">
            <v-list-item-icon>
              <v-icon>mdi-chart-line</v-icon>
            </v-list-item-icon>
            <v-list-item-title>終止治療人數分析圖</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/dropoutDynamic">
            <v-list-item-icon>
              <v-icon>mdi-chart-line</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dropout動態分析</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/changeReason">
            <v-list-item-icon>
              <v-icon>mdi-chart-line</v-icon>
            </v-list-item-icon>
            <v-list-item-title>異動原因分析</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container class="px-4 py-0 fill-height" fluid>
        <v-row class="fill-height">
          <v-col>
            <router-view></router-view>
              <Snackbar />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from '@/components/_partial/Snackbar.vue'


export default {
  name: "App",
  components: {
    Snackbar
  },
  computed: {
    miniVariant() {
      return this.$vuetify.breakpoint.smAndDown || this.mini;
    },
    navigationDrawer() {
      return {
        backgroundColor: '#1f2e3d'
      }
    }
  },
  data: () => ({
    mini: false,
    drawer: true
  }),
  methods: {
    toggleMini() {
      this.mini = !this.mini
    }
  },
};
</script>

<style scoped>
.theme--dark.v-application {
  background-color: #181818;
}

.theme--dark.v-card,
.theme--dark.v-data-table,
.theme--dark.v-tabs-items,
.theme--dark.v-tabs .v-tabs-bar {
  background-color: #232323;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #111;
}

::-webkit-scrollbar-thumb {
  background-color: #222;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #444;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.6);
  background-color: #333;
}

::-webkit-scrollbar-track:hover {
  background-color: #292929;
}

.min-logo {
  padding-right: 0 !important;
  padding-left: 6px !important;
}
</style>