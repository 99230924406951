<template>
    <div>
        <v-row>
            <v-col cols="6" sm="4" md="2">
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date"
                            label="報表月份"
                            dense
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        type="month"
                        locale="tw"
                        @input="menu = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="6" sm="4" md="2">
                <v-btn depressed color="primary" @click="queryMonthReport">
                    查詢
                </v-btn>
            </v-col>
        </v-row>
        <!-- <v-alert type="error" v-if="errorMsg">
            {{errorMsg}}
        </v-alert> -->
        <vue-excel-editor v-model="dataList" v-show="dataList.length" @cellFocus="checkCell" ref="grid">
            <vue-excel-column field="name" label="每日的紀錄" width="200px" readonly/>
            <vue-excel-column field="day1" label="1" width="40px" text-align="center" type="number" :change="changeDay1"/>
            <vue-excel-column field="day2" label="2" width="40px" text-align="center" type="number" :change="changeDay2"/>
            <vue-excel-column field="day3" label="3" width="40px" text-align="center" type="number" :change="changeDay3"/>
            <vue-excel-column field="day4" label="4" width="40px" text-align="center" type="number" :change="changeDay4"/>
            <vue-excel-column field="day5" label="5" width="40px" text-align="center" type="number" :change="changeDay5"/>
            <vue-excel-column field="day6" label="6" width="40px" text-align="center" type="number" :change="changeDay6"/>
            <vue-excel-column field="day7" label="7" width="40px" text-align="center" type="number" :change="changeDay7"/>
            <vue-excel-column field="day8" label="8" width="40px" text-align="center" type="number" :change="changeDay8"/>
            <vue-excel-column field="day9" label="9" width="40px" text-align="center" type="number" :change="changeDay9"/>
            <vue-excel-column field="day10" label="10" width="40px" text-align="center" type="number" :change="changeDay10"/>
            <vue-excel-column field="day11" label="11" width="40px" text-align="center" type="number" :change="changeDay11"/>
            <vue-excel-column field="day12" label="12" width="40px" text-align="center" type="number" :change="changeDay12"/>
            <vue-excel-column field="day13" label="13" width="40px" text-align="center" type="number" :change="changeDay13"/>
            <vue-excel-column field="day14" label="14" width="40px" text-align="center" type="number" :change="changeDay14"/>
            <vue-excel-column field="day15" label="15" width="40px" text-align="center" type="number" :change="changeDay15"/>
            <vue-excel-column field="day16" label="16" width="40px" text-align="center" type="number" :change="changeDay16"/>
            <vue-excel-column field="day17" label="17" width="40px" text-align="center" type="number" :change="changeDay17"/>
            <vue-excel-column field="day18" label="18" width="40px" text-align="center" type="number" :change="changeDay18"/>
            <vue-excel-column field="day19" label="19" width="40px" text-align="center" type="number" :change="changeDay19"/>
            <vue-excel-column field="day20" label="20" width="40px" text-align="center" type="number" :change="changeDay20"/>
            <vue-excel-column field="day21" label="21" width="40px" text-align="center" type="number" :change="changeDay21"/>
            <vue-excel-column field="day22" label="22" width="40px" text-align="center" type="number" :change="changeDay22"/>
            <vue-excel-column field="day23" label="23" width="40px" text-align="center" type="number" :change="changeDay23"/>
            <vue-excel-column field="day24" label="24" width="40px" text-align="center" type="number" :change="changeDay24"/>
            <vue-excel-column field="day25" label="25" width="40px" text-align="center" type="number" :change="changeDay25"/>
            <vue-excel-column field="day26" label="26" width="40px" text-align="center" type="number" :change="changeDay26"/>
            <vue-excel-column field="day27" label="27" width="40px" text-align="center" type="number" :change="changeDay27"/>
            <vue-excel-column field="day28" label="28" width="40px" text-align="center" type="number" :change="changeDay28"/>
            <vue-excel-column field="day29" label="29" width="40px" text-align="center" type="number" :change="changeDay29"/>
            <vue-excel-column field="day30" label="30" width="40px" text-align="center" type="number" :change="changeDay30"/>
            <vue-excel-column field="day31" label="31" width="40px" text-align="center" type="number" :change="changeDay31"/>
            <vue-excel-column field="total" label="總計" width="40px" text-align="center" type="number" readonly />
        </vue-excel-editor>
        
        <div v-if="notFound">
            <v-row>
                <v-col>
                    {{year}}/{{month}}月無報表，請點選建立按鈕
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn depressed color="primary" @click="createMonthReport">
                        建立
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-container fill-height fluid v-if="dataList.length">
            <v-row align="center" justify="center">
                <v-col cols="12" sm="4">
                    <v-btn depressed color="primary" @click="saveMonthReport">
                    存檔
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-if="dataList.length">
            <v-row>
                <v-col cols="12" sm="2">
                    本院收案追蹤人數
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec5Andc6}}
                </v-col>
                <v-col cols="12" sm="2">
                    住院治療人次
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec6}}
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="2">
                    本院未歸類追蹤人數  
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec7Andc8}}
                </v-col>
                <v-col cols="12" sm="2">
                    住院治療人數
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec6_1}}
                </v-col>
                <v-col cols="12" sm="2">
                    導管出口處發炎人數
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec14}}
                </v-col>
            </v-row>
             <v-row>
                <v-col cols="12" sm="2">
                    本院長期追蹤總治療人數
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec5Andc6 + calculatec7Andc8}}
                </v-col>
                <v-col cols="12" sm="2">
                    腹膜炎發生人數 
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec12}}
                </v-col>
                <v-col cols="12" sm="2">
                    導管出口處發炎率 
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec69}}
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="2">
                    它院就診人數 
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec11}}
                </v-col>
                 <v-col cols="12" sm="2">
                    腹膜炎發生率 
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec64}}
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="2">
                    總照護人數 
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec5Andc6 + calculatec7Andc8 + calculatec11}}
                </v-col>
                 <v-col cols="12" sm="2">
                    皮下隧道發炎人數 
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec13}}
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="2">
                    透析>3M人數
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec33}}
                </v-col>
                 <v-col cols="12" sm="2">
                    皮下隧道發炎率 
                </v-col>
                <v-col cols="12" sm="2">
                    {{calculatec66}}
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {queryMonthReportYM, createMonthReport, updateMonthReport} from '@/apis/monthReport.js'
//import {tip} from '@/apis/utils'

export default {
    name: 'MonthReport',
    data() {
        return {
            date : new Date().toISOString().substr(0, 7),
            menu : false,
            dataList : [],
            errorMsg : "",
            errors: [],
            year : "",
            month : "",
            cellColPos : "",
        }
    },
    mounted() {
        this.queryMonthReport()
    },
    computed: {
        notFound() {
            return this.dataList.length < 1
        },
        calculatec5Andc6() {    //本院收案追蹤人數
            return Number(this.dataList[8].total) + Number(this.dataList[9].total)
        },
        calculatec7Andc8() {    //本院未歸類追蹤人數 
            return Number(this.dataList[11].total) + Number(this.dataList[12].total)
        },
        calculatec11() {        //它院就診人數     
            return Number(this.dataList[15].total)
        },
        calculatec33() {        //透析>3M人數
            return Number(this.dataList[38].total)
        },
        calculatec6() {         //住院治療人次
            return Number(this.dataList[9].total)
        },
        calculatec6_1() {       //住院治療人數
            return Number(this.dataList[10].total)
        },
        calculatec12() {       //腹膜炎發生人數
            return Number(this.dataList[17].total)
        },
        calculatec64() {    //(門診治療人日數+住院治療人日數)/30/腹膜炎發生人數
            if(this.dataList[17].total==0)  return 0
            let reuslt = (Number(this.dataList[8].total) + Number(this.dataList[9].total))/30/Number(this.dataList[17].total)
            let resultPercent=Number(reuslt*100).toFixed(2);
            resultPercent+="%";   
            return resultPercent
        },
        calculatec13() {       //皮下隧道發炎人數
            return Number(this.dataList[18].total)
        },
        calculatec66() {    //(門診治療人日數+住院治療人日數)/30/皮下隧道發炎人數
            if(this.dataList[18].total==0) return 0
            let reuslt = (Number(this.dataList[8].total) + Number(this.dataList[9].total))/30/Number(this.dataList[18].total)
            let resultPercent=Number(reuslt*100).toFixed(2);
            resultPercent+="%";   
            return resultPercent
        },
        calculatec14() {       //導管出口處發炎人數
            return Number(this.dataList[19].total)
        },
        calculatec69() {    //(門診治療人日數+住院治療人日數)/30/導管出口處發炎人數
            if(this.dataList[19].total==0) return 0
            let reuslt = (Number(this.dataList[8].total) + Number(this.dataList[9].total))/30/Number(this.dataList[19].total)
            let resultPercent=Number(reuslt*100).toFixed(2);
            resultPercent+="%"
            return resultPercent
        },
    },
    methods: {
        createMonthReport() {
            this.errorMsg = ""
            const [year, month] = this.date.split("-")
            const monthReportRequest = { header : {}, body : { year, month } }
            createMonthReport( monthReportRequest)
                .then(response => {
                if(response.data.header.rtnCode=="T0000") {
                    this.queryMonthReport()
                }else {
                    this.errorMsg = response.data.header.rtnMsg
                    this.$store.dispatch('snackbar/openSnackbar', {
                        msg : response.data.header.rtnMsg,
                        color : 'red'
                    })
                }
            })
            .catch(e => {
                this.errors.push(e)
            })
        },
        queryMonthReport() {
            const [year, month] = this.date.split("-")
            queryMonthReportYM(year, month)
                .then(response => {
                    const { data } = response
                    const { body } = data
                    const { dataList, year, month, days } = body

                    this.dataList = dataList
                    this.year = year
                    this.month = month

                    const fieldDay29 = this.$refs.grid.fields.find(f => f.name === 'day29')
                    const fieldDay30 = this.$refs.grid.fields.find(f => f.name === 'day30')
                    const fieldDay31 = this.$refs.grid.fields.find(f => f.name === 'day31')

                    const daysInMonth = days
                    fieldDay29.invisible = daysInMonth < 29
                    fieldDay30.invisible = daysInMonth < 30
                    fieldDay31.invisible = daysInMonth < 31

                    this.$refs.grid.$forceUpdate()
                })
                .catch(e => {
                    this.errors.push(e)
                })
        },
        saveMonthReport() {
            const monthReportRequest = { header : {}, body : { dataList : [] } };
            this.dataList[3].day1 = Number(this.dataList[4].day1) + Number(this.dataList[5].day1) + Number(this.dataList[6].day1) +Number(this.dataList[7].day1)
            this.dataList[3].day2 = Number(this.dataList[4].day2) + Number(this.dataList[5].day2) + Number(this.dataList[6].day2) +Number(this.dataList[7].day2)
            this.dataList[3].day3 = Number(this.dataList[4].day3) + Number(this.dataList[5].day3) + Number(this.dataList[6].day3) +Number(this.dataList[7].day3)
            this.dataList[3].day4 = Number(this.dataList[4].day4) + Number(this.dataList[5].day4) + Number(this.dataList[6].day4) +Number(this.dataList[7].day4)
            this.dataList[3].day5 = Number(this.dataList[4].day5) + Number(this.dataList[5].day5) + Number(this.dataList[6].day5) +Number(this.dataList[7].day5)
            this.dataList[3].day6 = Number(this.dataList[4].day6) + Number(this.dataList[5].day6) + Number(this.dataList[6].day6) +Number(this.dataList[7].day6)
            this.dataList[3].day7 = Number(this.dataList[4].day7) + Number(this.dataList[5].day7) + Number(this.dataList[6].day7) +Number(this.dataList[7].day7)
            this.dataList[3].day8 = Number(this.dataList[4].day8) + Number(this.dataList[5].day8) + Number(this.dataList[6].day8) +Number(this.dataList[7].day8)
            this.dataList[3].day9 = Number(this.dataList[4].day9) + Number(this.dataList[5].day9) + Number(this.dataList[6].day9) +Number(this.dataList[7].day9)
            this.dataList[3].day10 = Number(this.dataList[4].day10) + Number(this.dataList[5].day10) + Number(this.dataList[6].day10) +Number(this.dataList[7].day10)
            this.dataList[3].day11 = Number(this.dataList[4].day11) + Number(this.dataList[5].day11) + Number(this.dataList[6].day11) +Number(this.dataList[7].day11)
            this.dataList[3].day12 = Number(this.dataList[4].day12) + Number(this.dataList[5].day12) + Number(this.dataList[6].day12) +Number(this.dataList[7].day12)
            this.dataList[3].day13 = Number(this.dataList[4].day13) + Number(this.dataList[5].day13) + Number(this.dataList[6].day13) +Number(this.dataList[7].day13)
            this.dataList[3].day14 = Number(this.dataList[4].day14) + Number(this.dataList[5].day14) + Number(this.dataList[6].day14) +Number(this.dataList[7].day14)
            this.dataList[3].day15 = Number(this.dataList[4].day15) + Number(this.dataList[5].day15) + Number(this.dataList[6].day15) +Number(this.dataList[7].day15)
            this.dataList[3].day16 = Number(this.dataList[4].day16) + Number(this.dataList[5].day16) + Number(this.dataList[6].day16) +Number(this.dataList[7].day16)
            this.dataList[3].day17 = Number(this.dataList[4].day17) + Number(this.dataList[5].day17) + Number(this.dataList[6].day17) +Number(this.dataList[7].day17)
            this.dataList[3].day18 = Number(this.dataList[4].day18) + Number(this.dataList[5].day18) + Number(this.dataList[6].day18) +Number(this.dataList[7].day18)
            this.dataList[3].day19 = Number(this.dataList[4].day19) + Number(this.dataList[5].day19) + Number(this.dataList[6].day19) +Number(this.dataList[7].day19)
            this.dataList[3].day20 = Number(this.dataList[4].day20) + Number(this.dataList[5].day20) + Number(this.dataList[6].day20) +Number(this.dataList[7].day20)
            this.dataList[3].day21 = Number(this.dataList[4].day21) + Number(this.dataList[5].day21) + Number(this.dataList[6].day21) +Number(this.dataList[7].day21)
            this.dataList[3].day22 = Number(this.dataList[4].day22) + Number(this.dataList[5].day22) + Number(this.dataList[6].day22) +Number(this.dataList[7].day22)
            this.dataList[3].day23 = Number(this.dataList[4].day23) + Number(this.dataList[5].day23) + Number(this.dataList[6].day23) +Number(this.dataList[7].day23)
            this.dataList[3].day24 = Number(this.dataList[4].day24) + Number(this.dataList[5].day24) + Number(this.dataList[6].day24) +Number(this.dataList[7].day24)
            this.dataList[3].day25 = Number(this.dataList[4].day25) + Number(this.dataList[5].day25) + Number(this.dataList[6].day25) +Number(this.dataList[7].day25)
            this.dataList[3].day26 = Number(this.dataList[4].day26) + Number(this.dataList[5].day26) + Number(this.dataList[6].day26) +Number(this.dataList[7].day26)
            this.dataList[3].day27 = Number(this.dataList[4].day27) + Number(this.dataList[5].day27) + Number(this.dataList[6].day27) +Number(this.dataList[7].day27)
            this.dataList[3].day28 = Number(this.dataList[4].day28) + Number(this.dataList[5].day28) + Number(this.dataList[6].day28) +Number(this.dataList[7].day28)
            this.dataList[3].day29 = Number(this.dataList[4].day29) + Number(this.dataList[5].day29) + Number(this.dataList[6].day29) +Number(this.dataList[7].day29)
            this.dataList[3].day30 = Number(this.dataList[4].day30) + Number(this.dataList[5].day30) + Number(this.dataList[6].day30) +Number(this.dataList[7].day30)
            this.dataList[3].day31 = Number(this.dataList[4].day31) + Number(this.dataList[5].day31) + Number(this.dataList[6].day31) +Number(this.dataList[7].day31)  
            
            this.dataList[3].total = Number(this.dataList[3].day1) + Number(this.dataList[3].day2) + 
                    Number(this.dataList[3].day3) + Number(this.dataList[3].day4) + Number(this.dataList[3].day5) + 
                    Number(this.dataList[3].day6) + Number(this.dataList[3].day7) + Number(this.dataList[3].day8) + 
                    Number(this.dataList[3].day9) + Number(this.dataList[3].day10) + Number(this.dataList[3].day11) + 
                    Number(this.dataList[3].day12) + Number(this.dataList[3].day13) + Number(this.dataList[3].day14) + 
                    Number(this.dataList[3].day15) + Number(this.dataList[3].day16) + Number(this.dataList[3].day17) + 
                    Number(this.dataList[3].day18) + Number(this.dataList[3].day19) + Number(this.dataList[3].day20) + 
                    Number(this.dataList[3].day21) + Number(this.dataList[3].day22) + Number(this.dataList[3].day23) + 
                    Number(this.dataList[3].day24) + Number(this.dataList[3].day25) + Number(this.dataList[3].day26) + 
                    Number(this.dataList[3].day27) + Number(this.dataList[3].day28) + Number(this.dataList[3].day29) +
                    Number(this.dataList[3].day30) + Number(this.dataList[3].day31)    
            monthReportRequest.body.dataList = this.dataList
            updateMonthReport( monthReportRequest)
                .then(response => {
                    if(response.data.header.rtnCode=="T0000") {
                        this.$store.dispatch('snackbar/openSnackbar',{
                            msg:'存檔成功',
                            color:'primary'
                        })
                    }else {
                        this.errorMsg = response.data.header.rtnMsg
                        this.$store.dispatch('snackbar/openSnackbar', {
                            msg : response.data.header.rtnMsg,
                            color : 'red'
                        })
                    }
                })
            .catch(e => {
                this.errors.push(e)
            })
        },
        calculateTotal(newVal, oldVal, row) {
            if(oldVal==null) {
                row.total = Number(row.total) + Number(newVal)
            }else {
                row.total = Number(newVal) - Number(oldVal) + Number(row.total)
            }
            if(row.column=="COLUMN04") {
                return
            }
            if(row.column=="COLUMN04_1" || row.column=="COLUMN04_2" || row.column=="COLUMN04_3" || row.column=="COLUMN04_4") { 
                let dayArray = [5, 6, 7, 8]
                dayArray.splice(dayArray.indexOf(row.sequence), 1);
                let column5678Total1 = 0
                let column5678Total2 = 0
                let column5678Total3 = 0
                let column5678Total4 = 0
                let column5678Total5 = 0
                let column5678Total6 = 0
                let column5678Total7 = 0
                let column5678Total8 = 0
                let column5678Total9 = 0
                let column5678Total10 = 0
                let column5678Total11 = 0
                let column5678Total12 = 0
                let column5678Total13 = 0
                let column5678Total14 = 0
                let column5678Total15 = 0
                let column5678Total16 = 0
                let column5678Total17 = 0
                let column5678Total18 = 0
                let column5678Total19 = 0
                let column5678Total20 = 0
                let column5678Total21 = 0
                let column5678Total22 = 0
                let column5678Total23 = 0
                let column5678Total24 = 0
                let column5678Total25 = 0
                let column5678Total26 = 0
                let column5678Total27 = 0
                let column5678Total28 = 0
                let column5678Total29 = 0
                let column5678Total30 = 0
                let column5678Total31 = 0
                dayArray.forEach(element => {
                    column5678Total1 += Number(this.dataList[element-1].day1)
                    column5678Total2 += Number(this.dataList[element-1].day2)
                    column5678Total3 += Number(this.dataList[element-1].day3)
                    column5678Total4 += Number(this.dataList[element-1].day4)
                    column5678Total5 += Number(this.dataList[element-1].day5)
                    column5678Total6 += Number(this.dataList[element-1].day6)
                    column5678Total7 += Number(this.dataList[element-1].day7)
                    column5678Total8 += Number(this.dataList[element-1].day8)
                    column5678Total9 += Number(this.dataList[element-1].day9)
                    column5678Total10 += Number(this.dataList[element-1].day10)
                    column5678Total11 += Number(this.dataList[element-1].day11)
                    column5678Total12 += Number(this.dataList[element-1].day12)
                    column5678Total13 += Number(this.dataList[element-1].day13)
                    column5678Total14 += Number(this.dataList[element-1].day14)
                    column5678Total15 += Number(this.dataList[element-1].day15)
                    column5678Total16 += Number(this.dataList[element-1].day16)
                    column5678Total17 += Number(this.dataList[element-1].day17)
                    column5678Total18 += Number(this.dataList[element-1].day18)
                    column5678Total19 += Number(this.dataList[element-1].day19)
                    column5678Total20 += Number(this.dataList[element-1].day20)
                    column5678Total21 += Number(this.dataList[element-1].day21)
                    column5678Total22 += Number(this.dataList[element-1].day22)
                    column5678Total23 += Number(this.dataList[element-1].day23)
                    column5678Total24 += Number(this.dataList[element-1].day24)
                    column5678Total25 += Number(this.dataList[element-1].day25)
                    column5678Total26 += Number(this.dataList[element-1].day26)
                    column5678Total27 += Number(this.dataList[element-1].day27)
                    column5678Total28 += Number(this.dataList[element-1].day28)
                    column5678Total29 += Number(this.dataList[element-1].day29)
                    column5678Total30 += Number(this.dataList[element-1].day30)
                    column5678Total31 += Number(this.dataList[element-1].day31)
                });
                switch (this.cellColPos) {
                    case 1 :
                        this.dataList[3].day1 = Number(column5678Total1) + Number(newVal)
                        break
                    case 2 :
                        this.dataList[3].day2 = Number(column5678Total2) + Number(newVal)
                        break
                    case 3 :
                        this.dataList[3].day3 = Number(column5678Total3) + Number(newVal)
                        break
                    case 4 :
                        this.dataList[3].day4 = Number(column5678Total4) + Number(newVal)
                        break
                    case 5 :
                        this.dataList[3].day5 = Number(column5678Total5) + Number(newVal)
                        break
                    case 6 :
                        this.dataList[3].day6 = Number(column5678Total6) + Number(newVal)
                        break
                    case 7 :
                        this.dataList[3].day7 = Number(column5678Total7) + Number(newVal)
                        break
                    case 8 :
                        this.dataList[3].day8 = Number(column5678Total8) + Number(newVal)
                        break
                    case 9 :
                        this.dataList[3].day9 = Number(column5678Total9) + Number(newVal)
                        break
                    case 10 :
                        this.dataList[3].day10 = Number(column5678Total10) + Number(newVal)
                        break
                    case 11 :
                        this.dataList[3].day11 = Number(column5678Total11) + Number(newVal)
                        break
                    case 12 :
                        this.dataList[3].day12 = Number(column5678Total12) + Number(newVal)
                        break
                    case 13 :
                        this.dataList[3].day13 = Number(column5678Total13) + Number(newVal)
                        break
                    case 14 :
                        this.dataList[3].day14 = Number(column5678Total14) + Number(newVal)
                        break
                    case 15 :
                        this.dataList[3].day15 = Number(column5678Total15) + Number(newVal)
                        break
                    case 16 :
                        this.dataList[3].day16 = Number(column5678Total16) + Number(newVal)
                        break
                    case 17 :
                        this.dataList[3].day17 = Number(column5678Total17) + Number(newVal)
                        break
                    case 18 :
                        this.dataList[3].day18 = Number(column5678Total18) + Number(newVal)
                        break
                    case 19 :
                        this.dataList[3].day19 = Number(column5678Total19) + Number(newVal)
                        break
                    case 20 :
                        this.dataList[3].day20 = Number(column5678Total20) + Number(newVal)
                        break
                    case 21 :
                        this.dataList[3].day21 = Number(column5678Total21) + Number(newVal)
                        break
                    case 22 :
                        this.dataList[3].day22 = Number(column5678Total22) + Number(newVal)
                        break
                    case 23 :
                        this.dataList[3].day23 = Number(column5678Total23) + Number(newVal)
                        break
                    case 24 :
                        this.dataList[3].day24 = Number(column5678Total24) + Number(newVal)
                        break
                    case 25 :
                        this.dataList[3].day25 = Number(column5678Total25) + Number(newVal)
                        break
                    case 26 :
                        this.dataList[3].day26 = Number(column5678Total26) + Number(newVal)
                        break
                    case 27 :
                        this.dataList[3].day27 = Number(column5678Total27) + Number(newVal)
                        break
                    case 28 :
                        this.dataList[3].day28 = Number(column5678Total28) + Number(newVal)
                        break
                    case 29 :
                        this.dataList[3].day29 = Number(column5678Total29) + Number(newVal)
                        break
                    case 30 :
                        this.dataList[3].day30 = Number(column5678Total30) + Number(newVal)
                        break
                    case 31 :
                        this.dataList[3].day31 = Number(column5678Total31) + Number(newVal)
                        break
                    default :
                        console.log("default")
                }
                this.dataList[3].total = Number(this.dataList[3].day1) + Number(this.dataList[3].day2) + 
                    Number(this.dataList[3].day3) + Number(this.dataList[3].day4) + Number(this.dataList[3].day5) + 
                    Number(this.dataList[3].day6) + Number(this.dataList[3].day7) + Number(this.dataList[3].day8) + 
                    Number(this.dataList[3].day9) + Number(this.dataList[3].day10) + Number(this.dataList[3].day11) + 
                    Number(this.dataList[3].day12) + Number(this.dataList[3].day13) + Number(this.dataList[3].day14) + 
                    Number(this.dataList[3].day15) + Number(this.dataList[3].day16) + Number(this.dataList[3].day17) + 
                    Number(this.dataList[3].day18) + Number(this.dataList[3].day19) + Number(this.dataList[3].day20) + 
                    Number(this.dataList[3].day21) + Number(this.dataList[3].day22) + Number(this.dataList[3].day23) + 
                    Number(this.dataList[3].day24) + Number(this.dataList[3].day25) + Number(this.dataList[3].day26) + 
                    Number(this.dataList[3].day27) + Number(this.dataList[3].day28) + Number(this.dataList[3].day29) +
                    Number(this.dataList[3].day30) + Number(this.dataList[3].day31)
            }
        },
        checkCell(cell) {
            this.cellColPos = cell.colPos
        },
        changeDay1(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay2(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay3(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay4(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay5(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay6(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay7(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay8(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay9(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay10(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay11(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay12(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay13(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay14(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay15(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay16(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay17(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay18(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay19(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay20(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay21(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay22(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay23(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay24(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay25(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay26(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay27(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay28(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay29(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay30(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        },
        changeDay31(newVal, oldVal, row) {
            this.calculateTotal(newVal, oldVal, row)
        }
    }
}
</script>
