<template>
  <div>
    <v-row>
      <v-col cols="6" sm="4" md="2">
        <v-select
          v-model="year"
          :items="selectYear"
          :menu-props="{ offsetX: true }"
          dense
          label="選擇年度"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn depressed color="primary" @click="queryDirectorReportY">
          查詢
        </v-btn>
      </v-col>
    </v-row>
    <vue-excel-editor v-model="dataList" v-if="dataList.length">
      <vue-excel-column field="name" label="項目" width="200px" readonly/>
      <vue-excel-column field="month1" label="1月" width="80px" text-align="center" type="number" readonly/>
      <vue-excel-column field="month2" label="2月" width="80px" text-align="center" type="number" readonly/>
      <vue-excel-column field="month3" label="3月" width="80px" text-align="center" type="number" readonly/>
      <vue-excel-column field="month4" label="4月" width="80px" text-align="center" type="number" readonly/>
      <vue-excel-column field="month5" label="5月" width="80px" text-align="center" type="number" readonly/>
      <vue-excel-column field="month6" label="6月" width="80px" text-align="center" type="number" readonly/>
      <vue-excel-column field="month7" label="7月" width="80px" text-align="center" type="number" readonly/>
      <vue-excel-column field="month8" label="8月" width="80px" text-align="center" type="number" readonly/>
      <vue-excel-column field="month9" label="9月" width="80px" text-align="center" type="number" readonly/>
      <vue-excel-column field="month10" label="10月" width="80px" text-align="center" type="number" readonly/>
      <vue-excel-column field="month11" label="11月" width="80px" text-align="center" type="number" readonly/>
      <vue-excel-column field="month12" label="12月" width="80px" text-align="center" type="number" readonly/>
      <vue-excel-column field="yearAverage" label="年度平均" width="80px" text-align="center" type="number" readonly/>
      <vue-excel-column field="yearTotal" label="年度總計" width="80px" text-align="center" type="number" readonly/>
    </vue-excel-editor>
  </div>
</template>

<script>
import {queryDirectorReport, queryDirectorReportY} from '@/apis/directorReport.js'

export default {
    name: 'DirectorReport',
    data() {
        return {
            date : new Date().toISOString().substr(0, 7),
            menu : false,
            dataList : [],
            errorMsg : "",
            errors: [],
            year : "",
            selectYear : []
        }
    },
    mounted() {
        this.queryDirectorReport()
        this.genYears()
    },
    methods: {
        queryDirectorReport() {
          queryDirectorReport().then(response => {
            this.dataList = response.data.body.dataList
            this.year = response.data.body.year
          }).catch(e => {
            this.errors.push(e)
          })
        },
        queryDirectorReportY() {
            queryDirectorReportY(this.year).then(response => {
              this.dataList = response.data.body.dataList
              this.year = response.data.body.year
            }).catch(e => {
              this.errors.push(e)
            })
        },
        genYears() {
          const year = new Date().getFullYear()
          this.selectYear = Array.from({length: year - 2019}, (value, index) => 2020 + index)
        }
    }
}
</script>
