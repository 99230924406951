<script>
  import { Bar } from 'vue-chartjs'
  import Chart from 'chart.js'

  export default {
    extends: Bar,
    props: {
      dataList: {
        type: Array,
        require: true
      }
    },
    mounted() {
      this.renderChart({
        labels: ['終止治療人數', '死亡', '轉HD', '腎移植', '轉院'],
        datasets: this.dataList
      }, {responsive: true, maintainAspectRatio: false})
    },
    methods : {
      renderChart (data, options) {
        if (this.$data._chart) this.$data._chart.destroy()
        if (!this.$refs.canvas) throw new Error('Please remove the <template></template> tags from your chart component. See https://vue-chartjs.org/guide/#vue-single-file-components')
        this.$data._chart = new Chart(
          this.$refs.canvas.getContext('2d'), {
            type: 'bar',
            data: data,
            options: options
            //plugins: this.$data._plugins
          }
        )
      }
    }
  }
</script>