<template>
  <div class="container">
    <v-row dense>
      <v-col cols="12" sm="2" md="2">
        <v-select
          v-model="queryCondition.condition"
          :items="selectCondition"
          item-text="conditionText"
          item-value="conditionValue"
          dense
          label="查詢條件"
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense v-if="queryCondition.condition">
      <v-col cols="12" sm="2" md="2">
        <v-select
          v-model="queryCondition.startYear"
          :items="selectYear"
          dense
          label="開始年份"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="1" md="2" v-if="queryCondition.condition=='MONTH'">
        <v-select
          v-model="queryCondition.startMonth"
          :items="selectMonth"
          dense
          label="開始月份"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2" md="2" v-if="queryCondition.condition=='SEASON'">
        <v-select
          v-model="queryCondition.startSeason"
          :items="selectSeason"
          item-text="seasonText"
          item-value="seasonValue"
          dense
          label="季"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2" md="2" v-if="queryCondition.condition=='YEAR' || queryCondition.condition=='MONTH'
                || queryCondition.condition=='SEASON'">
        <v-select
          v-model="queryCondition.endYear"
          :items="selectYear"
          dense
          label="結束年份"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="1" md="2" v-if="queryCondition.condition=='MONTH'">
        <v-select
          v-model="queryCondition.endMonth"
          :items="selectMonth"
          dense
          label="結束月份"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2" md="2" v-if="queryCondition.condition=='SEASON'">
        <v-select
          v-model="queryCondition.endSeason"
          :items="selectSeason"
          item-text="seasonText"
          item-value="seasonValue"
          dense
          label="季"
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="3" md="2">
        <v-btn depressed color="primary" @click="query">
          查詢
        </v-btn>
      </v-col>
    </v-row>
    <div class="Chart" v-if="dataList.length">
      <h1 style="text-align:center;">終止治療人數分析圖</h1>
      <abandonment-chart :dataList="dataList" />
    </div>
    <v-simple-table v-if="dataList.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              
            </th>
            <th class="text-left">
              終止治療人數
            </th>
            <th class="text-left">
              死亡
            </th>
            <th class="text-left">
              轉HD
            </th>
            <th class="text-left">
              腎移植
            </th>
            <th class="text-left">
              轉院
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="abandonment in dataList"
            :key="abandonment.label"
          >
            <td>{{ abandonment.label }}</td>
            <td>{{ abandonment.data[0] }}</td>
            <td>{{ abandonment.data[1] }}</td>
            <td>{{ abandonment.data[2] }}</td>
            <td>{{ abandonment.data[3] }}</td>
            <td>{{ abandonment.data[4] }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
  import AbandonmentChart from '@/charts/AbandonmentChart'
  import {queryAbandonment} from '@/apis/abandonment.js'

  export default {
    components: {
      AbandonmentChart
    },
    mounted() {
      this.genYears()
    },
    data() {
      return {
        queryCondition : {
          condition : "",
          startYear : "",
          endYear : "",
          startMonth : "",
          endMonth : "",
          startSeason : "",
          endSeason : ""
        },
        selectCondition : [
          {
            conditionText : "年",
            conditionValue : "YEAR"
          },
          {
            conditionText : "季",
            conditionValue : "SEASON"
          },
          {
            conditionText : "月",
            conditionValue : "MONTH"
          }
        ],
        date : null,
        menu : false,
        selectYear : [],
        selectSeason : [
          {
            seasonText : "第一季",
            seasonValue : "Q1"
          },
          {
            seasonText : "第二季",
            seasonValue : "Q2"
          },
          {
            seasonText : "第三季",
            seasonValue : "Q3"
          },
          {
            seasonText : "第四季",
            seasonValue : "Q4"
          },
        ],
        selectMonth : ["1","2","3","4","5","6","7","8","9","10","11","12"],
        dataList : [],
        errors: []
      }   
    },
    
    methods: {
      query() {
        if(this.queryCondition.startYear=='') {
              this.$store.dispatch('snackbar/openSnackbar', {
                msg : "請輸入開始年份",
                color : 'red'
              })
              return
            }
        switch(this.queryCondition.condition) {
          case 'YEAR':
            break
          case 'MONTH':
            if(this.queryCondition.startMonth=='') {
              this.$store.dispatch('snackbar/openSnackbar', {
                msg : "請輸入開始月份",
                color : 'red'
              })
              return
            }
            break
          case 'SEASON':
            if(this.queryCondition.startSeason=='') {
              this.$store.dispatch('snackbar/openSnackbar', {
                msg : "請輸入季",
                color : 'red'
              })
              return
            }
            break
          default:
            break
        }
        this.dataList = [];
        const abandonmentRequest = 
          { 
            header : {}, 
            body : { 
              condition : this.queryCondition.condition, 
              startYear : this.queryCondition.startYear,
              endYear : this.queryCondition.endYear,
              startMonth : this.queryCondition.startMonth,
              endMonth : this.queryCondition.endMonth,
              startSeason : this.queryCondition.startSeason,
              endSeason : this.queryCondition.endSeason
            } 
          }
        queryAbandonment(abandonmentRequest).then(response => {
          if(response.data.header.rtnCode=="T0000") {
            this.dataList = response.data.body.dataList
            if(this.dataList.length == 0) {
              this.$store.dispatch('snackbar/openSnackbar', {
                msg : "查無資料",
                color : 'red'
              })
            }
          }else {
            this.$store.dispatch('snackbar/openSnackbar', {
              msg : response.data.header.rtnMsg,
              color : 'red'
            })
          }
        }).catch(e => {
          this.errors.push(e)
        })
      },
      genYears() {
        const year = new Date().getFullYear()
        this.selectYear = Array.from({length: year - 2018}, (value, index) => 2019 + index)
      }
    }
  }
</script>