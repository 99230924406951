import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MonthReport from '../views/MonthReport.vue'
import DirectorReport from '../views/DirectorReport.vue'
import Abandonment from '../views/Abandonment.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/monthReport',
    name: 'MonthReport',
    component: MonthReport
  },
  {
    path: '/directorReport',
    name: 'DirectorReport',
    component: DirectorReport
  },
  {
    path: '/abandonment',
    name: 'Abandonment',
    component: Abandonment
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
